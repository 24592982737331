<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <form class="form" @submit.prevent="handleSubmit(summit)" autocomplete="off">
      <div v-if="update || join" class="form-row">
        <div class="col-md-3 mb-3" v-if="!join">
          <label class="form-label">Mã nhân viên:</label>
          <div class="d-flex align-items-center h-37"> {{ data.id + 100000 }}</div>
        </div>
        <div class="col-md-3 mb-3">
          <label class="form-label">Họ và tên:</label>
          <ValidationProvider vid="name" name="Tên nhân sự" rules="required"
                              v-slot="{ errors, classes }">
            <input type="hidden" v-model="name" :class="classes" name="name" ref="name">
            <div class="d-flex align-items-center h-37 edit-name" contenteditable="true" @input="onInputName" style="">
              {{ data.name }}
            </div>
            <div class="fv-plugins-message-container">
              <div data-field="name" data-validator="notEmpty"
                   class="fv-help-block">{{ errors[0] }}
              </div>
            </div>
          </ValidationProvider>
        </div>
        <div class="col-md-3 mb-3">
          <label class="form-label">Email: </label>
          <div class="d-flex align-items-center h-37"> {{ data.email }}</div>
        </div>
        <div class="col-md-3 mb-3">
          <label class="form-label">Số điện thoại:</label>
          <div class="d-flex align-items-center h-37"> {{ data.phone }}</div>
        </div>
        <div class="col-md-3 mb-3">
          <label class="form-label">Ngày sinh:</label>
          <div class="d-flex align-items-center h-37"> {{ getBirthDay() }}</div>
        </div>
        <div class="col-md-3 mb-3" v-if="join">
          <label class="form-label">Vị trí hiện tại:</label>
          <div class="d-flex align-items-center h-37"> {{ data.profile.account_type.name }}</div>
        </div>
        <div class="col-md-3 mb-3">
          <label class="form-label">Văn phòng:</label>
          <div class="d-flex align-items-center h-37">
            {{ data.profile.branch.name_alias ? data.profile.branch.name_alias : data.profile.branch.name }}
          </div>
        </div>
        <div class="col-md-3 mb-3">
          <label class="form-label">Giới tính:</label>
          <div class="d-flex align-items-center h-37"> {{ data.profile && data.profile.gender ? data.profile.gender : (data.request_confirm ? data.request_confirm.gender : '') }}</div>
        </div>
        <div class="col-md-3 mb-3">
          <label class="form-label">Địa chỉ:</label>
          <ValidationProvider vid="address" name="Địa chỉ" rules="required"
                              v-slot="{ errors, classes }">
            <input type="hidden" v-model="address" :class="classes" name="address" ref="address">
            <div class="d-flex align-items-center h-37 edit-name text-break" contenteditable="true"
                 v-on:input="onInputAddress"> {{ data.profile && data.profile.address ? data.profile.address : (data.request_confirm ? data.request_confirm.address : '') }}
            </div>
            <div class="fv-plugins-message-container">
              <div data-field="address" data-validator="notEmpty"
                   class="fv-help-block">{{ errors[0] }}
              </div>
            </div>
          </ValidationProvider>
        </div>
        <hr class="w-100" v-if="!join">
        <div class="col-md-6 col-lg-3 mb-3">
          <label class="form-label">Vị trí kiêm nhiệm</label>
          <edutalk-select v-model="plurality" class="w-100" collapse-tags
                          multiple clearable filterable placeholder="Chọn vị trí kiêm nhiệm">
            <edutalk-option
                v-for="item in kiemNghiemOption"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            >
            </edutalk-option>
          </edutalk-select>
        </div>
        <div class="col-md-6 col-lg-3 mb-3">
          <label class="form-label">Ngày bắt đầu làm việc</label>
          <ValidationProvider vid="time_start_work" name="Ngày bắt đầu làm việc" rules="required"
                              v-slot="{ errors, classes }">
            <el-date-picker format="yyyy-MM-dd"
                            ref="time_start_work"
                            name="time_start_work"
                            :class="classes"
                            value-format="yyyy-MM-dd"
                            type="date"
                            placeholder="Chọn ngày bắt đầu làm việc"
                            v-model="time_start_work"
                            style="width: 100%;">
            </el-date-picker>
            <div class="invalid-feedback" data-field="time_start_work" data-validator="notEmpty">
              {{ errors[0] }}
            </div>
          </ValidationProvider>
        </div>
        <div class="col-md-6 col-lg-3 mb-3">
          <label class="form-label">Vị trí cấp trên</label>
          <edutalk-select v-model="account_type"
                          class="w-100"
                          @change="handleAccountType()"
                          clearable
                          filterable
                          placeholder="Vị trí cấp trên">
            <edutalk-option
                v-for="item in optionAccountType"
                :key="item.id"
                :label="item.value"
                :value="item.id"
            >
            </edutalk-option>
          </edutalk-select>
        </div>
        <div class="col-md-6 col-lg-3 mb-3">
          <label class="form-label">Cấp bậc cấp trên</label>
          <edutalk-select v-model="business_level_id"
                          class="w-100"
                          @change="checkIsShowBoss()"
                          clearable
                          filterable
                          placeholder="Cấp bậc cấp trên">
            <edutalk-option
                v-for="item in businessLevels"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            >
            </edutalk-option>
          </edutalk-select>
        </div>
        <div class="col-md-6 col-lg-3 mb-3" v-if="is_show_boss">
          <label class="form-label">Cấp trên trực tiếp</label>
          <edutalk-select v-model="parent" v-loading="loading.loadingSelect" class="w-100" clearable filterable
                          placeholder="Chọn cấp trên trực tiếp" @clear="clearParent">
            <edutalk-option
                v-for="item in saleOption"
                :key="item.id"
                :label="item.text"
                :value="item.id"
                :selected="parentSelected"
                :disabled="item.disabled"
            >
              <span style="float: left">{{ item.text }}</span>
              <span class="ml-2" v-if="item.disabled"
                    style="float: right; color: #D32F2F; font-size: 13px; opacity: 0.5">Quá số lượng cấp dưới</span>
            </edutalk-option>
          </edutalk-select>
        </div>
        <div class="col-md-6 col-lg-3 mb-3" v-if="is_show_mechanism">
          <label class="form-label">Cơ chế thu nhập<span class="text-danger"></span></label>
          <edutalk-select v-model="data.profile.salary_mechanism_id"
                          class="w-100"
                          clearable
                          filterable
                          disabled
                          placeholder="Chưa có cơ chế thu nhập"
          >
            <edutalk-option
                v-for="item in mechanisms"
                :key="item.id"
                :label="item.name "
                :value="item.id"
            >
            </edutalk-option>
          </edutalk-select>
        </div>
      </div>
      <div v-if="update || confirm || join" class="form-now row">
        <el-divider v-if="data.veryfy_images && data.veryfy_images.length > 0" content-position="left">Xác minh danh tính</el-divider>
        <div class="col-md-12" v-if="data.veryfy_images && data.veryfy_images.length === 0 && confirm">Chưa có thông tin để xác minh danh
          tính
        </div>
        <div class="col-md-4 mb-3" v-for="(it, key) in data.veryfy_images ? data.veryfy_images : [] " :key="key">
          <el-image @click="showImageIdentity" class="image-verify img-identity" :src="it.url">
            <!--eslint-disable-->
            <div slot="placeholder" class="image-slot">
              Loading<span class="dot">...</span>
            </div>
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
        </div>

          <el-divider v-if="data.veryfy_images_request && data.veryfy_images_request.length > 0 && !data.veryfy_images && data.veryfy_images.length == 0" content-position="left">Xác minh danh tính</el-divider>
          <div class="col-md-12" v-if="data.veryfy_images_request && data.veryfy_images_request.length === 0 && confirm">Chưa có thông tin để xác minh danh
              tính
          </div>
          <div class="col-md-4 mb-3" v-for="(it, key) in data.veryfy_images_request ? data.veryfy_images_request : [] " :key="key">
              <el-image @click="showImageIdentity" class="image-verify img-identity" :src="it.url">
                  <!--eslint-disable-->
                  <div slot="placeholder" class="image-slot">
                      Loading<span class="dot">...</span>
                  </div>
                  <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                  </div>
              </el-image>
          </div>
        <div
            id="modal-show-img"
            class="detail-img-modal"
            onclick="this.style.display='none'"
            style="display: none"
        >
          <div class="detail-img-modal-content detail-img-animate-zoom">
                    <span
                        class="
                        detail-img-button
                        detail-img-hover-red
                        detail-img-xlarge
                        detail-img-display-topright
                    "
                    >x</span
                    >
            <img id="img-detail" style="width: 100%"/>
          </div>
        </div>
        <el-divider v-if="data.bankAccounts.length > 0 && (!update && !confirm) || join" content-position="left">
          Thông tin tài khoản ngân hàng
        </el-divider>
        <div class="row col-12" v-if="data.bankAccounts.length > 0 && (!update || join) && !confirm">
          <div class="col-md-3 mb-3">
            <label class="form-label">Chủ tài khoản:</label>
            <div class="d-flex align-items-center h-37"> {{
                data.bankAccounts[0].owner_name || 'Chưa có thông tin'
              }}
            </div>
          </div>
          <div class="col-md-3 mb-3">
            <label class="form-label">Tên ngân hàng:</label>
            <div class="d-flex align-items-center h-37"> {{ data.bankAccounts[0].ngan_hang || 'Chưa có thông tin' }}
            </div>
          </div>
          <div v-if="data.bankAccounts[0].ngan_hang === 'Agribank'" class="col-md-3 mb-3">
            <label class="form-label">Tỉnh</label>
            <div class="d-flex align-items-center h-37"> {{ data.bankAccounts[0].province || 'Chưa có thông tin' }}
            </div>
          </div>
          <div v-if="data.bankAccounts[0].ngan_hang === 'Agribank'" class="col-md-3 mb-3">
            <label class="form-label">Chi nhánh</label>
            <div class="d-flex align-items-center h-37"> {{ data.bankAccounts[0].chi_nhanh || 'Chưa có thông tin' }}
            </div>
          </div>
          <div class="col-md-3 mb-3">
            <label class="form-label">Số tài khoản</label>
            <div class="d-flex align-items-center h-37"> {{ data.bankAccounts[0].stk || 'Chưa có thông tin' }}</div>
          </div>
        </div>
        <div class="col-md-12" v-if="data.bankAccounts.length === 0 && (!update || join) && !confirm">Chưa có thông
          tin
        </div>
      </div>
      <button :disabled="loading.save" v-if="update && !join" class="btn btn-primary float-right mt-3 ml-3"
              type="submit">
        <i v-if="loading.save" class="el-icon-loading"></i>
        Cập nhật thông tin
      </button>
    </form>
    <button :disabled="loading.save" @click="acceptUser" v-if="join" class="btn btn-primary float-right mt-3 ml-3"
            type="submit">
      <i v-if="loading.save" class="el-icon-loading"></i>
      Duyệt ngay
    </button>
    <button :disabled="loading.save" @click="denied" v-if="join" class="btn btn-outline-danger float-right mt-3 ml-3"
            type="submit">
      <i v-if="loading.save" class="el-icon-loading"></i>
      Từ chối
    </button>
    <button :disabled="loading.confirm" @click="close" class="btn btn-outline-default float-right mt-3" type="submit">
      <i v-if="loading.save" class="el-icon-loading"></i>
      Đóng
    </button>
    <button :disabled="loading.confirm" @click="denied" v-if="confirm && data.veryfy_images.length > 0"
            class="btn btn-outline-info float-right mt-3 mr-3">
      <i v-if="loading.save" class="el-icon-loading"></i>
      Từ chối
    </button>
    <button :disabled="loading.confirm" @click="acceptUser" v-if="confirm "
            class="btn btn-primary float-right mt-3 mr-3" type="submit">
      <i v-if="loading.save" class="el-icon-loading"></i>
      Xác minh
    </button>
  </ValidationObserver>
</template>

<script>
import {mapGetters} from "vuex";
import {
  kiemNghiemOption,
  TAI_KHOAN_CAP_TREN,
  TAI_KHOAN_CAP_TREN_ONLY_SALE,
  SALE_LEADER,
  SALE_MEMBER, ACCOUNT_TYPE_TEXT_TO_INT, SALE_INTERN, STUDENT, UNG_VIEN, kiemNghiemNew,
} from "@/core/config/accountTypeOption";
import {GET_LEADER} from "@/core/services/store/service.module";
import {
  UPDATE_HUMAN_RESOURCE,
  REJECT_USER,
  ACCEPT_USER,
  COUNT_IS_WAIT_USER
} from "@/core/services/store/profile.module";
import lodash from 'lodash-es';
import deepdash from 'deepdash-es';
import {mechanismFull} from "@/core/config/mechanism";
import {GET_BUSINESS_LEVEL} from "@/core/services/store/business/level.module";

const _ = deepdash(lodash);
export default {
  props: {
    update: {
      type: Boolean,
      default: false
    },
    confirm: {
      type: Boolean,
      default: false
    },
    join: {
      type: Boolean,
      default: false
    },
    businessLevels: {
      type: Array,
      default: []
    },
    data: Object,
  },
  data() {
    return {
      mechanisms: mechanismFull,
      loading: {
        save: false,
        loadingSelect: false
      },
      item: {},
      plurality: null,
      account_type: null,
      business_level_id: null,
      branch_id: null,
      time_start_work: null,
      parent: null,
      name: '',
      address: '',
      parentSelected: null,
      saleOption: [],
      kiemNghiemOption: JSON.parse(JSON.stringify(kiemNghiemNew)),
      optionAccountType: [],
      is_show_boss: false,
      is_show_mechanism: false
    }
  },
  mounted() {

    if (this.data.user_boss) {
      this.business_level_id = this.data.user_boss.profile.business_level_id;
    }
    this.changeData()
    this.checkShowAccountType();
    this.setDefault();
    this.checkIsShowMechanism();
  },
  computed: {
    ...mapGetters(['currentUser'])
  },
  watch: {
    data: function () {
      if (this.data.user_boss) {
        this.business_level_id = this.data.user_boss.profile.business_level_id;
        this.account_type = this.data.user_boss.profile.account_type_id;
      }
      this.checkShowAccountType();
      this.changeData()
      this.setDefault();
      this.checkIsShowMechanism();
    }
  },
  methods: {
    setDefault() {
      if (this.data.parent_id) {
        this.parent = this.data.parent_id;
        this.is_show_boss = true;
      }
    },
    checkShowAccountType() {
      let profile = this.data.profile;
      this.optionAccountType = TAI_KHOAN_CAP_TREN;
      if (profile.account_type_id === SALE_MEMBER && (profile.business_level && profile.business_level.index > 3)) {
        this.optionAccountType = TAI_KHOAN_CAP_TREN_ONLY_SALE;
      }
      if (profile.account_type_id === SALE_INTERN || (profile.account_type_id === SALE_MEMBER && profile.business_level && profile.business_level.index > 1)) {
        this.optionAccountType = TAI_KHOAN_CAP_TREN;
      }
    },
    showImageIdentity(element) {
      document.getElementById("img-detail").src = element.srcElement.src;
      document.getElementById("modal-show-img").style.display = "block";
    },
    close() {
      this.$emit('close')
    },
    summit() {
      let params = {
        id: this.data.id,
        parent_id: typeof this.parent === 'number' ? this.parent : this.parentSelected,
        time_start_work: this.time_start_work,
        plurality: this.plurality,
        name: this.name,
        address: this.address,
      };
      this.$store.dispatch(UPDATE_HUMAN_RESOURCE, params)
          .then((response) => {
            if (response.error) {
              this.$notify({
                title: 'Warning',
                message: `Serve đang bận, vui lòng thử lại sau: ${response.data.message}`,
                type: 'warning'
              });
            } else {
              this.$message({
                type: 'success',
                message: 'Cập nhật thông tin thành công'
              });
              this.$emit('completed', response.data)
            }
          }).catch((res) => {
        if (res.data?.data?.message_validate_form) {
          this.$refs.form.setErrors(res.data.data.message_validate_form);
        }
      });
    },
    changeData() {
      if (this.data.profile.account_type.id !== SALE_LEADER) {
        _.remove(this.kiemNghiemOption, function (value) {
          return value.id === 'SLX'
        });
      } else {
        this.kiemNghiemOption = JSON.parse(JSON.stringify(kiemNghiemNew))
      }
      this.plurality = this.data.user_positions.map((position) => {
        return position.position;
      });
      this.name = this.data.name;
      this.address = this.data.profile && this.data.profile.address ? this.data.profile.address : (this.data.request_confirm ? this.data.request_confirm.address : '');

      this.account_type = this.data.account_type_parent;
      this.branch_id = this.data.profile.chi_nhanh_id;
      this.time_start_work = this.data.profile.start_to_work ? this.$moment(this.data.profile.start_to_work).format('YYYY-MM-DD') : null;
      _.remove(this.optionAccountType, function (value) {
        return value.name === 'QLHV' || value.name === 'sale intern'
      });
      this.parent = this.data.parent ? this.data.parent.name : null;
      this.parentSelected = this.data.parent ? this.data.parent.id : null;
      this.changeAccountType()
    },
    handleAccountType() {
      this.changeAccountType();
      this.getBusinessLevelByAccountType();
    },
    resetBoss() {
      this.saleOption = [];
      this.parent = null;
      this.data.parent_id = null;
    },
    changeAccountType() {
      let params = {};
      if (this.account_type) {
        this.loading.loadingSelect = true;
        params = _.merge(params, {account_type: this.account_type})
        params = _.merge(params, {branch_id: this.branch_id})
        params = _.merge(params, {business_level_id: this.business_level_id})
        this.$store.dispatch(GET_LEADER, params)
            .then((response) => {
              let sales = [];
              _.each(response.data, (item) => {
                sales.push({
                  id: item.id,
                  text: item.text,
                  disabled: item.disabled,
                })
              });
              this.saleOption = sales;
              this.loading.loadingSelect = false;
            });
      } else {
        this.saleOption = [];
      }
    },
    getBusinessLevelByAccountType() {
      this.businessLevels = [];
      this.business_level_id = null;
      if (this.account_type) {
        this.$store.dispatch(GET_BUSINESS_LEVEL, {
          limit: 10000,
          status: 1,
          account_type_id: ACCOUNT_TYPE_TEXT_TO_INT[this.account_type],
          user_id: this.data.id
        }).then((res) => {
          if (!res.error) {
            this.businessLevels = res.data.data
          }
        }).catch((e) => {
          console.log(e, 'error')
        });
      }
    },
    checkIsShowBoss() {
      this.resetBoss();
      this.is_show_boss = false;
      if (this.business_level_id && this.account_type) {
        this.is_show_boss = true;
        this.changeAccountType();
      }
    },
    checkIsShowMechanism() {
      this.is_show_mechanism = [UNG_VIEN, SALE_INTERN, SALE_MEMBER, STUDENT].includes(this.data.profile.account_type_id)
    },
    denied() {
      this.$prompt('Nhập lý do từ chối', 'Từ chối xác minh', {
        confirmButtonText: 'Xác nhận',
        cancelButtonText: 'Hủy',
        inputPlaceholder: 'Nhập...',
        inputValidator: (value) => {
          if (value) return true;
          return 'Lý do từ chối là bắt buộc';
        }
      }).then(({value}) => {
        this.rejectUser(value)
      });
    },
    rejectUser(reason) {
      let params = {
        id: this.data.id,
        reason: reason
      };
      this.loading.save = true
      this.$store.dispatch(REJECT_USER, params)
          .then((response) => {
            if (response.status === 422) {
              this.$notify({
                title: 'Warning',
                message: `Serve đang bận, vui lòng thử lại sau: ${response.data.message}`,
                type: 'warning'
              });
            } else {
              this.$message({
                type: 'success',
                message: 'Từ chối xác minh thành công'
              });
              this.$emit('completed', response.data)
            }
            this.loading.save = false
          });
    },
    acceptUser() {
      let params = {
        id: this.data.id,
        parent_id: typeof this.parent === 'number' ? this.parent : this.parentSelected,
        time_start_work: this.time_start_work,
        plurality: this.plurality,
        name: this.name,
        address: this.address,
      };
      this.$store.dispatch(ACCEPT_USER, params)
          .then((response) => {
            if (response.status === 422) {
              this.$notify({
                title: 'Warning',
                message: `Serve đang bận, vui lòng thử lại sau: ${response.data.message}`,
                type: 'warning'
              });
            } else {
              this.$message({
                type: 'success',
                message: 'Nhân sự đã được thêm thành công vào văn phòng, hãy cập nhật ngày bắt đầu làm việc và mã cấp trên cho nhân sự'
              });
              this.$emit('completed', response.data)
            }
            this.loading.save = false
          }).then(() => {
        let params = {is_wait: 1, is_update: true};
        this.$store.dispatch(COUNT_IS_WAIT_USER, params);
      });
    },

    clearParent() {
      this.parent = '';
      this.parentSelected = '';
    },

    onInputName(e) {
      this.name = e.target.innerText;
    },

    onInputAddress(e) {
      this.address = e.target.innerText;
    },
    getBirthDay() {
      if (!this.data.profile) {
        return '';
      }
      if (this.data.profile.birthday && this.data.profile.birthday != '0000-00-00') {
        return this.data.profile.birthday;
      }
      if (this.data.request_confirm) {
        return this.data.request_confirm.birthday;
      }
      return ''
    }
  },
}
</script>

<style>
div.edit-name:hover {
  border-radius: 10px;
  color: #fff;
  background-color: #5c4581;
  cursor: pointer;
  padding: 10px;
}
</style>
